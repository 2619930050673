import * as React from "react"
import Layout from "../components/Layout";
import localize from "../components/localize";
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import {PortableText} from "@portabletext/react";
import {GatsbyImage} from "gatsby-plugin-image";

export const query = graphql`
    {
    site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        offeneJobs{
        _type
        en
        de
        }
        keineJobs{
        _type
        en
        de
        }
        mehrInfo{
        _type
        en
        de
        }
    }
    }
    allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type}
       _rawQuote
        partner{
            title
            link
            logo { asset { gatsbyImageData}}
        }
    }
  }
  }
  allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        linkedin
        jobsexternal
        jobsexternallink
     }
     }
    }
    }
   `;

const NetzwerkPage = ({data, location}) => {

    const page = data.allSanityNetzwerk.edges[0].node;

    const settings = data.allSanitySettings.edges[0].node;

    return (
        <div id={"top"}>
            <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={page.title}>
                <SEO title={page.title + " | Dr. Barbara Stampf"}/>
                <div className={"jobs__outer"}>
                    <div className="color-bg bg-green left2"></div>
                    <div className="color-bg bg-blue right2"></div>
                    <div className="jobs__inner partner__inner">
                        <h1 className={"uppercase color-light"}>{page.title}</h1>
                        <PortableText value={page._rawQuote}></PortableText>
                        <div className="partner__list">
                            {page.partner.map(partner => {
                                return <a href={partner.link} target={"_blank"} className={"hover-moveup"}><GatsbyImage alt={partner.title} image={partner.logo.asset.gatsbyImageData}></GatsbyImage></a>
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default localize(NetzwerkPage)
